<template>
  <div id="my-account">
    <v-container
      class="pa-0"
      style="background-color: #ffffff; min-height: 790px; overflow: hidden"
    >
      <v-row justify="center" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="d-flex justify-end">
            <Images
              id="account-credentials-close-icon-1b3dfa52"
              class="float-right pointer mt-5 mr-5"
              v-on:click.native="$emit('clicked', false)"
              name="close.svg"
            />
          </div>
          <div class="d-flex justify-center">
            <Images name="my_account_img.svg" style="margin-top: -27px" />
          </div>
          <form style="width: 100%" @submit.prevent="onApply">
            <v-row justify="center">
              <v-col cols="10">
                <v-text-field
                  name="displayName"
                  v-model="form.displayName"
                  id="displayName"
                  label="Your Display Name"
                  aria-required="true"
                  :class="{
                    'error--text v-input--has-state':
                      ($v.form.displayName.$dirty &&
                        !$v.form.displayName.required) ||
                      ($v.form.displayName.$error &&
                        !$v.form.displayName.displayNameValid.valid &&
                        $v.form.displayName.required),
                  }"
                  hide-details
                  outlined
                />
                <div v-if="$v.form.displayName.$error" class="mylabel">
                  <Texting
                    type="p1 Fivethly"
                    v-if="!$v.form.displayName.required"
                    >Display Name is required</Texting
                  >
                  <Texting
                    type="p1 Fivethly"
                    v-else-if="
                      $v.form.displayName.$error &&
                      !$v.form.displayName.displayNameValid.valid &&
                      $v.form.displayName.required
                    "
                    >Display Name should contain only letters (a-z/A-Z)</Texting
                  >
                </div>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  name="email"
                  v-model="form.email"
                  id="email"
                  label="Your Email Address"
                  aria-required="true"
                  :class="{
                    'error--text v-input--has-state':
                      ($v.form.email.$dirty && !$v.form.email.required) ||
                      ($v.form.email.$dirty && !$v.form.email.email),
                  }"
                  hide-details
                  outlined
                />
                <div v-if="$v.form.email.$error" class="mylabel">
                  <Texting type="p1 Fivethly" v-if="!$v.form.email.required"
                    >Email is required</Texting
                  >
                  <Texting type="p1 Fivethly" v-if="!$v.form.email.email"
                    >Email address is not valid</Texting
                  >
                </div>
              </v-col>
              <v-col cols="10" style="padding-bottom: 40px; padding-top: 24px">
                <template>
                  <div style="width: 100%" class="phone-div">
                    <vue-tel-input
                      style="height: 56px"
                      id="telephone-id"
                      @focus="phoneInputFocus = true"
                      @blur="phoneInputFocus = false"
                      @close="onClose"
                      @country-changed="onCountryChanged($event)"
                      :style="{
                        border: phoneInputFocus
                          ? '2px solid #1976d2'
                          : '1px solid #A7A0B8',
                      }"
                      mode="international"
                      @input="onInput"
                      :autoDefaultCountry="false"
                      :validCharactersOnly="true"
                      :enabledCountryCode="true"
                      enabledFlags
                      :inputOptions="{
                        showDialCode: false,
                        tabindex: 1,
                        id: 'my-account-phone-input',
                      }"
                      v-model="form.phoneNumber"
                      outlined
                    ></vue-tel-input>
                  </div>
                </template>
                <div v-if="phoneValidError" class="mylabel">
                  <Texting type="p1 Fivethly">Phone number isn't valid</Texting>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center" class="change-pass-container">
              <v-col v-show="!showPassFields" cols="10" class="py-0">
                <Button
                  type="secondary"
                  size="block"
                  texting="p1bold grey10"
                  style="
                    padding-top: 16px !important;
                    padding-bottom: 16px !important;
                  "
                  :handleClick="showFields"
                >
                  <Images style="margin-right: 8px" name="lock.svg" />
                  <span v-if="user.method === 'google'">Set</span>
                  <span v-else>Change</span> Password
                </Button>
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
                v-if="
                  (user.method === 'local' ||
                    user.method === 'localAndGoogle') &&
                  showPassFields
                "
              >
                <v-row justify="center">
                  <v-col cols="10" style="padding-top: 0">
                    <v-text-field
                      name="oldPassword"
                      id="oldPassword"
                      v-model="passwordForm.oldPassword"
                      label="Old Password"
                      type="password"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state':
                          $v.passwordForm.oldPassword.$error,
                      }"
                      hide-details
                      outlined
                    />
                    <div
                      v-if="$v.passwordForm.oldPassword.$error"
                      class="mylabel"
                    >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.oldPassword.required"
                        >Password is required</Texting
                      >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.oldPassword.minLength"
                        >Password minimum length is 8 letters</Texting
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      name="newPassword"
                      id="newPassword"
                      v-model="passwordForm.newPassword"
                      label="New Password"
                      :type="show1 ? 'text' : 'password'"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state':
                          $v.passwordForm.newPassword.$error,
                      }"
                      hide-details
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon @click="show1 = !show1" v-if="show1">{{
                          svgPathEye
                        }}</v-icon>
                        <v-icon @click="show1 = !show1" v-else>{{
                          svgPathEyeOff
                        }}</v-icon>
                      </template>
                    </v-text-field>
                    <div
                      v-if="$v.passwordForm.newPassword.$error"
                      class="mylabel"
                    >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.newPassword.required"
                        >Password is required</Texting
                      >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.newPassword.minLength"
                        >Password minimum length is 8 letters</Texting
                      >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.newPassword.strength.valid"
                        >Min 6 symbols – at least 1 uppercase, 1 lowercase, 1
                        digit</Texting
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      name="confirmPassword"
                      id="confirmPassword"
                      v-model="passwordForm.confirmPassword"
                      label="Confirm Password"
                      :type="show2 ? 'text' : 'password'"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state':
                          $v.passwordForm.confirmPassword.$error,
                      }"
                      hide-details
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon @click="show2 = !show2" v-if="show2">{{
                          svgPathEye
                        }}</v-icon>
                        <v-icon @click="show2 = !show2" v-else>{{
                          svgPathEyeOff
                        }}</v-icon>
                      </template>
                    </v-text-field>
                    <div
                      v-if="$v.passwordForm.confirmPassword.$error"
                      class="mylabel"
                    >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.passwordForm.confirmPassword.sameAsPassword"
                        >Passwords do not match</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else-if="showPassFields" cols="12" class="pb-0">
                <v-row justify="center">
                  <v-col cols="10" style="padding-top: 0">
                    <v-text-field
                      name="newPassword"
                      id="newPassword"
                      v-model="createForm.newPassword"
                      label="Set Password"
                      :type="show1 ? 'text' : 'password'"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state':
                          $v.createForm.newPassword.$error,
                      }"
                      hide-details
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon @click="show1 = !show1" v-if="show1">{{
                          svgPathEye
                        }}</v-icon>
                        <v-icon @click="show1 = !show1" v-else>{{
                          svgPathEyeOff
                        }}</v-icon>
                      </template>
                    </v-text-field>
                    <div
                      v-if="$v.createForm.newPassword.$error"
                      class="mylabel"
                    >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.createForm.newPassword.required"
                        >Password is required</Texting
                      >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.createForm.newPassword.minLength"
                        >Password minimum length is 8 letters</Texting
                      >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.createForm.newPassword.strength.valid"
                        >Min 6 symbols – at least 1 uppercase, 1 lowercase, 1
                        digit</Texting
                      >
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      name="confirmPassword"
                      id="confirmPassword"
                      v-model="createForm.confirmPassword"
                      label="Confirm Password"
                      :type="show2 ? 'text' : 'password'"
                      aria-required="true"
                      v-bind:class="{
                        'error--text v-input--has-state':
                          $v.createForm.confirmPassword.$error,
                      }"
                      hide-details
                      outlined
                    >
                      <template v-slot:append>
                        <v-icon @click="show2 = !show2" v-if="show2">{{
                          svgPathEye
                        }}</v-icon>
                        <v-icon @click="show2 = !show2" v-else>{{
                          svgPathEyeOff
                        }}</v-icon>
                      </template>
                    </v-text-field>
                    <div
                      v-if="$v.createForm.confirmPassword.$error"
                      class="mylabel"
                    >
                      <Texting
                        type="p1 Fivethly"
                        v-if="!$v.createForm.confirmPassword.sameAsPassword"
                        >Passwords do not match</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" class="mb-5">
              <v-col cols="10" class="pt-10">
                <Button
                  id="account-credentials-save-b3c6aa94"
                  type="primary"
                  b_type="submit"
                  texting="p3bold mywhite"
                  size="block"
                  style="
                    padding-top: 16px !important;
                    padding-bottom: 16px !important;
                  "
                  :style="{
                    backgroundColor:
                      (this.showPassFields &&
                        (this.$v.passwordForm.oldPassword.$invalid ||
                          this.$v.passwordForm.newPassword.$invalid ||
                          this.$v.passwordForm.confirmPassword.$invalid) &&
                        (this.passwordForm.oldPassword ||
                          this.passwordForm.newPassword ||
                          this.passwordForm.confirmPassword)) ||
                      ((this.$v.createForm.newPassword.$invalid ||
                        this.$v.createForm.confirmPassword.$invalid) &&
                        (this.createForm.newPassword ||
                          this.createForm.confirmPassword)) ||
                      (!this.phoneValid && this.form.phoneNumber !== '') ||
                      this.form.phoneNumber.includes('-') ||
                      this.form.phoneNumber.substring(1).includes('+') ||
                      (this.form.phoneNumber.charAt(0) !== '+' &&
                        this.form.phoneNumber !== '') ||
                      this.$v.form.displayName.$invalid ||
                      this.$v.form.email.$invalid
                        ? 'rgba(158, 51, 224, .5)'
                        : '#9E33E0',
                  }"
                  :handleClick="
                    () => {
                      snackbar_name = true;
                      user.verifiedEmail
                        ? (snackbar_email = true)
                        : (snackbar_email = false);
                    }
                  "
                  >save changes</Button
                >
              </v-col>
            </v-row>
          </form>
          <div
            class="d-flex justify-center mx-5 mb-4 pa-5 mt-8"
            style="background: #dcf4ff; opacity: 0.7; border-radius: 10px"
          >
            <Images
              style="margin-top: -12px; margin-right: 12px"
              name="light.svg"
            />
            <Texting type="label1 faux-cyan">
              You can't use several accounts for one campaign. However, you can
              manage several campaigns with one account.
            </Texting>
          </div>
          <div
            class="d-flex justify-center mx-5 mb-8 pa-5"
            style="background: #dcf4ff; opacity: 0.7; border-radius: 10px"
          >
            <Images
              style="margin-top: -2px; margin-right: 12px"
              name="light.svg"
            />
            <Texting type="label1 faux-cyan">
              Did you know you can set up SMS Notifications to get critical
              alerts via SMS?
            </Texting>
          </div>
        </v-col>
      </v-row>
      <Confirmation
        text1="Please check your email and follow the instructions"
        :text2="`Just in case you forgot, it was - '${form.email.toLowerCase()}'`"
        ref="modal"
      />
      <Confirmation
        text1="Cool, you just changed your password"
        ref="passChanged"
      />
    </v-container>
  </div>
</template>

<script>
import myServices from "../services/resource";
import { mapState } from "vuex";
import axios from "axios";
import Button from "./Button.vue";
import Texting from "./Texting";
import Images from "./MyImages";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { strength } from "@/utils/passStrength";
import Confirmation from "./Confirmation.vue";
import { displayNameValid } from "@/utils/displayNameValid";
import { VueTelInput } from "vue-tel-input";
import { mdiEye } from "@mdi/js";
import { mdiEyeOff } from "@mdi/js";

import "vue-tel-input/dist/vue-tel-input.css";

export default {
  components: {
    VueTelInput,
    Images,
    Texting,
    Button,
    Confirmation,
  },
  data: () => ({
    svgPathEye: mdiEye,
    svgPathEyeOff: mdiEyeOff,
    phoneInputFocus: false,
    phoneValid: true,
    show1: false,
    show2: false,
    showPassFields: false,
    dialCode: null,
    phoneValidError: false,
    form: {
      displayName: "",
      email: "",
      phoneNumber: "",
    },
    passwordForm: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    createForm: {
      newPassword: "",
      confirmPassword: "",
    },
  }),
  validations: {
    form: {
      displayName: {
        required,
        displayNameValid,
      },
      email: {
        required,
        email,
      },
    },
    passwordForm: {
      oldPassword: {
        required,
        minLength: minLength(8),
      },
      newPassword: {
        required,
        minLength: minLength(8),
        strength,
      },
      confirmPassword: {
        sameAsPassword: sameAs("newPassword"),
      },
    },
    createForm: {
      newPassword: {
        required,
        minLength: minLength(8),
        strength,
      },
      confirmPassword: {
        sameAsPassword: sameAs("newPassword"),
      },
    },
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  async mounted() {
    this.form = {
      ...this.user,
    };
    if (!this.user.phoneNumber) {
      this.form.phoneNumber = "";
    } else {
      this.$nextTick(() => {
        this.form.phoneNumber = this.user.phoneNumber;
      });
    }
  },
  methods: {
    onCountryChanged(obj) {
      if (obj.dialCode) {
        this.form.phoneNumber = "+" + obj.dialCode;
      }
    },
    onClose() {
      if (this.dialCode && this.form.phoneNumber === "") {
        this.form.phoneNumber = "+" + this.dialCode;
      }
    },
    showFields() {
      this.showPassFields = true;
      //this.$toasted.show( 'Changes are saved ', { type: 'success', theme: 'bubble' }).goAway(2000);
    },
    onInput(str, obj) {
      this.phoneValid = obj.valid;
      this.dialCode = obj.country.dialCode;
      if (
        obj.valid ||
        (this.form.phoneNumber === "" &&
          !this.form.phoneNumber.includes("-") &&
          !this.form.phoneNumber.substring(1).includes("+") &&
          this.form.phoneNumber.charAt(0) === "+") ||
        this.form.phoneNumber === "" ||
        this.form.phoneNumber.substring(1) === obj.country.dialCode
      ) {
        this.phoneValidError = false;
        this.phoneValid = true;
      } else {
        this.phoneValidError = true;
        this.phoneValid = false;
      }
      /*obj.isValid ? document.getElementById("my-account-phone-input").type = 'text' : document.getElementById("my-account-phone-input").type = 'number';
            if(document.getElementById("my-account-phone-input").value === '' && !obj.isValid) {
              this.form.phoneNumber = '';
            }*/
    },
    async onApply() {
      if (
        this.passwordForm.oldPassword ||
        this.passwordForm.newPassword ||
        this.passwordForm.confirmPassword
      ) {
        if (
          this.$v.passwordForm.oldPassword.$invalid ||
          this.$v.passwordForm.newPassword.$invalid ||
          this.$v.passwordForm.confirmPassword.$invalid
        ) {
          return this.$v.passwordForm.$touch();
        }
      } else if (
        this.createForm.newPassword ||
        this.createForm.confirmPassword
      ) {
        if (
          this.$v.createForm.newPassword.$invalid ||
          this.$v.createForm.confirmPassword.$invalid
        ) {
          return this.$v.createForm.$touch();
        }
      }

      if (
        (!this.phoneValid && this.form.phoneNumber) ||
        this.form.phoneNumber.includes("-") ||
        this.form.phoneNumber.substring(1).includes("+") ||
        (this.form.phoneNumber.charAt(0) !== "+" &&
          this.form.phoneNumber !== "")
      ) {
        this.phoneValidError = true;
        return;
      }
      if (this.$v.form.displayName.$invalid || this.$v.form.email.$invalid) {
        this.$v.form.$touch();
        return;
      }
      if (this.form.phoneNumber.substring(1) === this.dialCode)
        this.form.phoneNumber = "";
      let data = {
        displayName: this.form.displayName,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
      };

      if (this.passwordForm.oldPassword)
        data.oldPassword = this.passwordForm.oldPassword;
      if (this.passwordForm.newPassword)
        data.newPassword = this.passwordForm.newPassword;
      if (this.passwordForm.confirmPassword)
        data.confirmPassword = this.passwordForm.confirmPassword;
      if (this.createForm.newPassword)
        data.newPassword = this.createForm.newPassword;
      if (this.createForm.confirmPassword)
        data.confirmPassword = this.createForm.confirmPassword;

      let notEmpty;
      if (
        this.form.displayName !== this.user.displayName ||
        this.form.email.toLowerCase() !== this.user.email ||
        this.form.phoneNumber !== this.user.phoneNumber ||
        this.passwordForm.newPassword ||
        this.createForm.newPassword
      ) {
        notEmpty = true;
      }

      if (notEmpty) {
        await axios
          .put("/api/user/updateAccountInfo", data)
          .then((res) => {
            this.$store.dispatch("getAuthUser");
            this.$store.dispatch("getProjects");
            const { data } = res.data;
            this.close();
            this.$nextTick(() => {
              if (data.displayName) this.user.displayName = data.displayName;
              if (data.email) {
                this.user.email = data.email;
                this.$refs.modal.show();
              }
              if (data.phoneNumber) {
                this.user.phoneNumber = data.phoneNumber;
              } else this.user.phoneNumber = "";
            });
            if (data.passwordChanged) {
              this.$refs.passChanged.show();
            }
            this.$toasted
              .show("Changes are saved ", {
                type: "success",
                theme: "bubble",
              })
              .goAway(2000);
          })
          .catch((error) => {
            myServices.showErrorMessage(this, error);
            /*let message = "Oops, something went wrong";
                        if (error.response && error.response.data) {
                          message = error.response.data.message;
                        }
                        this.$toasted.error(message, {
                          duration: 5000,
                          className: "toast-error",
                        });*/
          });
      }
    },
    close: function () {
      this.passwordForm.oldPassword = "";
      this.passwordForm.newPassword = "";
      this.passwordForm.confirmPassword = "";
      this.createForm.newPassword = "";
      this.createForm.confirmPassword = "";
      this.showPassFields = false;
    },
  },
};
</script>

<style scoped>
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

#telephone-id {
  border: 1px solid #a7a0b8;
  border-radius: 5px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
