<template>
  <div>
    <v-dialog
      v-if="dialog"
      id="upsell-modal-4a536a22"
      persistent
      overlay-opacity="0.95"
      v-model="dialog"
      max-width="880px"
      min-wi
    >
      <v-card
        style="
          background: #ffffff;
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.06);
          border-radius: 14px;
          z-index: inherit;
        "
        id="upsell-card-5879c754"
      >
        <v-container style="padding-bottom: 38px">
          <v-row>
            <v-col cols="1" md="2"></v-col>
            <v-col
              cols="9"
              md="8"
              style="padding-top: 40px; padding-bottom: 9px"
            >
              <Texting type="p4-3 grey1" style="text-align: center"
                >Thanks for subscribing! One more thing...</Texting
              >
            </v-col>
            <v-col cols="2" md="2" class="pt-2 pr-5 pl-0">
              <Images
                name="close.svg"
                class="pointer float-end"
                @click.native="close"
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="10" md="8" class="px-0 pb-2" style="max-width: 500px">
              <Texting type="p2 grey1" style="text-align: center"
                >Here's a super sweet deal for you.<br />
                Upgrade now or within the next <b>48 hours</b> to get</Texting
              >
              <div
                class="mt-8 mb-10"
                style="
                  background: #fbf5ff;
                  border-radius: 13px;
                  padding: 13px 30px;
                "
              >
                <Texting
                  type="p4bold grey10"
                  style="text-align: center; box-sizing: border-box"
                  >Limited Special offer 6-months 30% OFF</Texting
                >
              </div>
              <Texting type="p2 grey1" style="text-align: center"
                >Too good to be true? Not this time...
                <b>but hurry up!!</b></Texting
              >
              <div class="d-flex align-center justify-center mt-6">
                <Texting
                  type="p4 grey19"
                  style="
                    -webkit-text-decoration-line: line-through;
                    text-decoration-line: line-through;
                    padding-right: 32px;
                  "
                  >$9.9/day</Texting
                >
                <div
                  class="new-price"
                  style="
                    background: #f4e1ff;
                    border: 1px solid rgba(186, 186, 186, 0.29);
                    border-radius: 10px;
                    padding: 7px 46px;
                  "
                >
                  <Texting type="p4 grey10">$6.9/day</Texting>
                </div>
              </div>
              <div
                @click="onUpgradeClick"
                class="d-flex upgrade-button align-center mx-auto"
              >
                <Texting type="p4bold mywhite">Upgrade Now!</Texting>
                <Images
                  style="position: absolute; right: 32px"
                  name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631734803/images/up-arrow_1_v6yrpp.svg"
                />
              </div>
              <Texting type="p3 grey1" style="text-align: center"
                >uh oh, this promo expires in</Texting
              >
            </v-col>
          </v-row>
          <v-row
            justify="center"
            style="
              border-top: 1px solid #edcfff;
              border-bottom: 1px solid #edcfff;
            "
          >
            <v-col
              cols="auto"
              class="d-flex"
              style="padding-top: 15px; padding-bottom: 5px"
            >
              <div id="upsell-modal-timer">
                <flip-countdown
                  v-if="deadline && deadline.length > 0"
                  :showDays="true"
                  :showSeconds="false"
                  :deadline="deadline"
                ></flip-countdown>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="11" class="upsell-footer">
              <Images
                name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631737824/images/klds5r01sykfbucdkumu.svg"
              />
              <div class="upsell-footer-text">
                <Texting type="p2strong2 grey21"
                  >With one of our campaigns, we had our team cover 2-hour
                  shifts around the clock, to manually update our perks. I had
                  to wake up at 4 AM just to see if our perks had run out or not
                  it was insane. Perfection gives me peace of mind and lets me
                  focus on more important things.</Texting
                >
                <Texting
                  type="p2 grey21"
                  style="text-align: center; margin-top: 39px !important"
                  >Ivaiylo Kalburdzhiev</Texting
                >
                <Texting
                  type="label6"
                  style="color: rgba(3, 3, 3, 0.4); text-align: center"
                  >Crowdfunding Consultant, Founder of
                  dayonefunding.com</Texting
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import myServices from "../services/resource";
import FlipCountdown from "vue2-flip-countdown";

export default {
  components: {
    //Heading,
    //Button,
    Images,
    Texting,
    FlipCountdown,
  },
  props: {
    active: Boolean,
    project: Object,
    deadline: String,
  },
  data: () => ({
    dialog: false,
    timeLeft: 172800,
  }),
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
      } else if (this.deadline.length === 0) {
        this.dialog = false;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    // formattedTimeLeft() {
    //   let hours = Math.floor(this.timeLeft / 3600);
    //   let minutes = Math.floor((this.timeLeft - hours * 3600) / 60);
    //   let seconds = this.timeLeft % 60;
    //   if (seconds < 10) {
    //     seconds = `0${seconds}`
    //   }
    //   if (minutes < 10) {
    //     minutes = `0${minutes}`
    //   }
    //   if (hours < 10) {
    //     hours = `0${hours}`
    //   }
    //   return {
    //     hours: hours,
    //     minutes: minutes,
    //     seconds: seconds,
    //   }
    // }
  },
  mounted() {
    setInterval(() => (this.timeLeft -= 1), 1000);
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onUpgradeClick() {
      myServices.myAnalyticsEvents(this, "Upsell", "pop-up button click");
      this.$emit("upgrade");
    },
  },
};
</script>
<style scoped>
.upgrade-button {
  background: linear-gradient(
    94.37deg,
    #ffc714 -18.36%,
    #ff9600 34.3%,
    #ff5008 108.67%
  );
  border-radius: 13px;
  padding: 10px 32px 8px 71px;
  position: relative;
  margin-top: 31px;
  margin-bottom: 38px;
  cursor: pointer;
  max-width: 324px;
}
.upsell-footer {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1631737846/images/Untitled_design_1_1_dfw6uj.png");
  background-color: #ffffff;
  padding-top: 17px;
  padding-left: 21px;
  margin-top: 52px;
  min-height: 331px;
  box-shadow: 0 0 35px rgba(154, 161, 171, 0.15);
  border-radius: 22px;
  background-repeat: no-repeat;
  background-position: right;
}
.upsell-footer-text {
  margin-top: 10px !important;
  max-width: 452px;
  margin-left: auto !important;
  margin-right: 225px !important;
}
@media only screen and (max-width: 600px) {
  .upgrade-button {
    padding: 10px 0px 8px 30px !important;
  }
  .upsell-footer {
    background-position: bottom;
  }
  .upsell-footer-text {
    margin-right: auto !important;
    margin-bottom: 300px !important;
  }
  .new-price {
    padding: 7px 20px !important;
  }
}
</style>
