var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"my-account"}},[_c('v-container',{staticClass:"pa-0",staticStyle:{"background-color":"#ffffff","min-height":"790px","overflow":"hidden"}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('Images',{staticClass:"float-right pointer mt-5 mr-5",attrs:{"id":"account-credentials-close-icon-1b3dfa52","name":"close.svg"},nativeOn:{"click":function($event){return _vm.$emit('clicked', false)}}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('Images',{staticStyle:{"margin-top":"-27px"},attrs:{"name":"my_account_img.svg"}})],1),_c('form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.onApply.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                  'error--text v-input--has-state':
                    (_vm.$v.form.displayName.$dirty &&
                      !_vm.$v.form.displayName.required) ||
                    (_vm.$v.form.displayName.$error &&
                      !_vm.$v.form.displayName.displayNameValid.valid &&
                      _vm.$v.form.displayName.required),
                },attrs:{"name":"displayName","id":"displayName","label":"Your Display Name","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.form.displayName),callback:function ($$v) {_vm.$set(_vm.form, "displayName", $$v)},expression:"form.displayName"}}),(_vm.$v.form.displayName.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.form.displayName.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Display Name is required")]):(
                    _vm.$v.form.displayName.$error &&
                    !_vm.$v.form.displayName.displayNameValid.valid &&
                    _vm.$v.form.displayName.required
                  )?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Display Name should contain only letters (a-z/A-Z)")]):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                  'error--text v-input--has-state':
                    (_vm.$v.form.email.$dirty && !_vm.$v.form.email.required) ||
                    (_vm.$v.form.email.$dirty && !_vm.$v.form.email.email),
                },attrs:{"name":"email","id":"email","label":"Your Email Address","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.form.email.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.form.email.email)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Email address is not valid")]):_vm._e()],1):_vm._e()],1),_c('v-col',{staticStyle:{"padding-bottom":"40px","padding-top":"24px"},attrs:{"cols":"10"}},[[_c('div',{staticClass:"phone-div",staticStyle:{"width":"100%"}},[_c('vue-tel-input',{staticStyle:{"height":"56px"},style:({
                      border: _vm.phoneInputFocus
                        ? '2px solid #1976d2'
                        : '1px solid #A7A0B8',
                    }),attrs:{"id":"telephone-id","mode":"international","autoDefaultCountry":false,"validCharactersOnly":true,"enabledCountryCode":true,"enabledFlags":"","inputOptions":{
                      showDialCode: false,
                      tabindex: 1,
                      id: 'my-account-phone-input',
                    },"outlined":""},on:{"focus":function($event){_vm.phoneInputFocus = true},"blur":function($event){_vm.phoneInputFocus = false},"close":_vm.onClose,"country-changed":function($event){return _vm.onCountryChanged($event)},"input":_vm.onInput},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1)],(_vm.phoneValidError)?_c('div',{staticClass:"mylabel"},[_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Phone number isn't valid")])],1):_vm._e()],2)],1),_c('v-row',{staticClass:"change-pass-container",attrs:{"justify":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassFields),expression:"!showPassFields"}],staticClass:"py-0",attrs:{"cols":"10"}},[_c('Button',{staticStyle:{"padding-top":"16px !important","padding-bottom":"16px !important"},attrs:{"type":"secondary","size":"block","texting":"p1bold grey10","handleClick":_vm.showFields}},[_c('Images',{staticStyle:{"margin-right":"8px"},attrs:{"name":"lock.svg"}}),(_vm.user.method === 'google')?_c('span',[_vm._v("Set")]):_c('span',[_vm._v("Change")]),_vm._v(" Password ")],1)],1),(
                (_vm.user.method === 'local' ||
                  _vm.user.method === 'localAndGoogle') &&
                _vm.showPassFields
              )?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        _vm.$v.passwordForm.oldPassword.$error,
                    },attrs:{"name":"oldPassword","id":"oldPassword","label":"Old Password","type":"password","aria-required":"true","hide-details":"","outlined":""},model:{value:(_vm.passwordForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "oldPassword", $$v)},expression:"passwordForm.oldPassword"}}),(_vm.$v.passwordForm.oldPassword.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.passwordForm.oldPassword.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.passwordForm.oldPassword.minLength)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password minimum length is 8 letters")]):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        _vm.$v.passwordForm.newPassword.$error,
                    },attrs:{"name":"newPassword","id":"newPassword","label":"New Password","type":_vm.show1 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show1)?_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}],null,false,3987671010),model:{value:(_vm.passwordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "newPassword", $$v)},expression:"passwordForm.newPassword"}}),(_vm.$v.passwordForm.newPassword.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.passwordForm.newPassword.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.passwordForm.newPassword.minLength)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password minimum length is 8 letters")]):_vm._e(),(!_vm.$v.passwordForm.newPassword.strength.valid)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Min 6 symbols – at least 1 uppercase, 1 lowercase, 1 digit")]):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        _vm.$v.passwordForm.confirmPassword.$error,
                    },attrs:{"name":"confirmPassword","id":"confirmPassword","label":"Confirm Password","type":_vm.show2 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show2)?_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}],null,false,3908780033),model:{value:(_vm.passwordForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "confirmPassword", $$v)},expression:"passwordForm.confirmPassword"}}),(_vm.$v.passwordForm.confirmPassword.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.passwordForm.confirmPassword.sameAsPassword)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Passwords do not match")]):_vm._e()],1):_vm._e()],1)],1)],1):(_vm.showPassFields)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        _vm.$v.createForm.newPassword.$error,
                    },attrs:{"name":"newPassword","id":"newPassword","label":"Set Password","type":_vm.show1 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show1)?_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}]),model:{value:(_vm.createForm.newPassword),callback:function ($$v) {_vm.$set(_vm.createForm, "newPassword", $$v)},expression:"createForm.newPassword"}}),(_vm.$v.createForm.newPassword.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.createForm.newPassword.required)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.createForm.newPassword.minLength)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Password minimum length is 8 letters")]):_vm._e(),(!_vm.$v.createForm.newPassword.strength.valid)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Min 6 symbols – at least 1 uppercase, 1 lowercase, 1 digit")]):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{class:{
                      'error--text v-input--has-state':
                        _vm.$v.createForm.confirmPassword.$error,
                    },attrs:{"name":"confirmPassword","id":"confirmPassword","label":"Confirm Password","type":_vm.show2 ? 'text' : 'password',"aria-required":"true","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.show2)?_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEye))]):_c('v-icon',{on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(_vm._s(_vm.svgPathEyeOff))])]},proxy:true}]),model:{value:(_vm.createForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.createForm, "confirmPassword", $$v)},expression:"createForm.confirmPassword"}}),(_vm.$v.createForm.confirmPassword.$error)?_c('div',{staticClass:"mylabel"},[(!_vm.$v.createForm.confirmPassword.sameAsPassword)?_c('Texting',{attrs:{"type":"p1 Fivethly"}},[_vm._v("Passwords do not match")]):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"mb-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-10",attrs:{"cols":"10"}},[_c('Button',{staticStyle:{"padding-top":"16px !important","padding-bottom":"16px !important"},style:({
                  backgroundColor:
                    (this.showPassFields &&
                      (this.$v.passwordForm.oldPassword.$invalid ||
                        this.$v.passwordForm.newPassword.$invalid ||
                        this.$v.passwordForm.confirmPassword.$invalid) &&
                      (this.passwordForm.oldPassword ||
                        this.passwordForm.newPassword ||
                        this.passwordForm.confirmPassword)) ||
                    ((this.$v.createForm.newPassword.$invalid ||
                      this.$v.createForm.confirmPassword.$invalid) &&
                      (this.createForm.newPassword ||
                        this.createForm.confirmPassword)) ||
                    (!this.phoneValid && this.form.phoneNumber !== '') ||
                    this.form.phoneNumber.includes('-') ||
                    this.form.phoneNumber.substring(1).includes('+') ||
                    (this.form.phoneNumber.charAt(0) !== '+' &&
                      this.form.phoneNumber !== '') ||
                    this.$v.form.displayName.$invalid ||
                    this.$v.form.email.$invalid
                      ? 'rgba(158, 51, 224, .5)'
                      : '#9E33E0',
                }),attrs:{"id":"account-credentials-save-b3c6aa94","type":"primary","b_type":"submit","texting":"p3bold mywhite","size":"block","handleClick":function () {
                    _vm.snackbar_name = true;
                    _vm.user.verifiedEmail
                      ? (_vm.snackbar_email = true)
                      : (_vm.snackbar_email = false);
                  }}},[_vm._v("save changes")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-center mx-5 mb-4 pa-5 mt-8",staticStyle:{"background":"#dcf4ff","opacity":"0.7","border-radius":"10px"}},[_c('Images',{staticStyle:{"margin-top":"-12px","margin-right":"12px"},attrs:{"name":"light.svg"}}),_c('Texting',{attrs:{"type":"label1 faux-cyan"}},[_vm._v(" You can't use several accounts for one campaign. However, you can manage several campaigns with one account. ")])],1),_c('div',{staticClass:"d-flex justify-center mx-5 mb-8 pa-5",staticStyle:{"background":"#dcf4ff","opacity":"0.7","border-radius":"10px"}},[_c('Images',{staticStyle:{"margin-top":"-2px","margin-right":"12px"},attrs:{"name":"light.svg"}}),_c('Texting',{attrs:{"type":"label1 faux-cyan"}},[_vm._v(" Did you know you can set up SMS Notifications to get critical alerts via SMS? ")])],1)])],1),_c('Confirmation',{ref:"modal",attrs:{"text1":"Please check your email and follow the instructions","text2":("Just in case you forgot, it was - '" + (_vm.form.email.toLowerCase()) + "'")}}),_c('Confirmation',{ref:"passChanged",attrs:{"text1":"Cool, you just changed your password"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }