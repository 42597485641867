<template>
  <div style="position: relative; min-height: 70px">
    <v-app-bar
      id="app-header-user"
      fixed
      flat
      style="height: 70px; position: fixed; z-index: 11"
    >
      <v-toolbar-title style="padding-top: 13px; padding-left: 14px">
        <router-link v-if="$route.query.blank !== 'true'" to="/">
          <Images
            id="header-user-logo-8d24e526"
            name="logo.svg"
            @click.native="logoClick"
            class="pointer"
          />
        </router-link>
        <Images
          v-else
          id="header-user-logo-8d24e536"
          name="logo.svg"
          @click.native="logoClick"
          class="pointer"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu id="header-user-menu-d6d407b0" max-width="200px" offset-y>
          <template v-slot:activator="{ on }">
            <div
              id="header-user-menu-activator-7d9390a2"
              v-on="on"
              @click="menuClicked"
              class="d-flex justify-center align-center pointer"
            >
              <div v-if="user" class="mr-4" style="text-align: right">
                <Texting
                  id="header-user-displayName-9cd56ee0"
                  type="p1 grey1"
                  >{{ user.displayName }}</Texting
                >
                <Texting
                  v-on="on"
                  id="header-user-email-aa291736"
                  type="label1 grey3"
                  >{{ user.email }}</Texting
                >
              </div>
              <div class="mr-5" style="position: relative">
                <div
                  v-if="menuArchiveAdded() || menuInvoiceAdded()"
                  class="red-circle"
                ></div>
                <Images name="profile_pic.svg" />
                <div
                  v-if="
                    (menuArchiveAdded() || menuInvoiceAdded()) && arrowVisible()
                  "
                  class="arrow-menu"
                  style="position: absolute"
                >
                  <Images
                    id="header-user-menu-arrow-icon-c54ce5c4"
                    name="menu_arrow.svg"
                  />
                </div>
              </div>
            </div>
          </template>
          <v-list
            style="
              box-shadow: 0 0 75px rgba(0, 0, 0, 0.17);
              border-radius: 5px;
              overflow: hidden;
            "
            id="header-user-menu-list-d61f8ba4"
            v-cloak
            flat
          >
            <v-list-item-group>
              <v-list-item
                @click="drawer = true"
                align="center"
                id="header-user-list-item-1"
                style="margin-top: 17px; margin-bottom: 20px"
                link
              >
                <Images
                  name="user_solid.svg"
                  width="25"
                  height="25"
                  class="list-image"
                />
                <Texting type="p2 grey1">My Account</Texting>
              </v-list-item>
              <router-link
                v-if="invoices.length > 0"
                to="/invoices"
                class="text_decor"
              >
                <div id="header-user-list-item-2" class="invoice-div">
                  <v-list-item>
                    <Images
                      name="invoices.svg"
                      width="25"
                      height="25"
                      class="list-image"
                    />
                    <Texting type="p2 grey2">My Invoices</Texting>
                  </v-list-item>
                </div>
              </router-link>
              <router-link
                v-if="inArchive.length > 0"
                to="/archives"
                class="text_decor"
              >
                <div
                  id="header-user-list-item-3"
                  :class="{ 'archive-div-not-invoice': invoices.length === 0 }"
                >
                  <v-list-item>
                    <Images
                      name="archive.svg"
                      width="25"
                      height="25"
                      class="list-image"
                    />
                    <Texting type="p2 grey2">Archive</Texting>
                  </v-list-item>
                </div>
              </router-link>
              <!--<div class="help-div">
                <v-list-item>
                  <Images
                    name="help.svg"
                    width="25"
                    height="25"
                    class="list-image"
                  />
                  <Texting type="p2 grey2">Help</Texting>
                </v-list-item>

</div>-->
              <div id="header-user-list-item-4" class="help-div">
                <v-list-item
                  @click.prevent="logout"
                  style="margin-bottom: 12px"
                >
                  <Images
                    name="sign_out.svg"
                    width="25"
                    height="25"
                    class="list-image"
                  />
                  <Texting type="p2 grey2">Sign Out</Texting>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <transition name="account-menu">
      <div
        v-if="drawer"
        id="my-account-credentials"
        style="
          z-index: 2000;
          overflow-y: auto;
          position: fixed;
          max-height: 100vh;
          right: 0;
          top: 0;
          box-shadow: -3px 0 40px rgba(0, 0, 0, 0.2);
        "
      >
        <AccountCredentials @clicked="onClicked" />
      </div>
    </transition>
    <div
      v-if="scheduleDate !== ''"
      style="margin-top: 70px; background-color: #9e33e0"
    >
      <v-row justify="center" align="center">
        <v-col
          cols="12"
          class="d-md-flex d-none justify-space-between"
          style="padding: 8px 12px 4px 12px"
        >
          <Texting class="d-flex ml-8" type="p4 mywhite"
            >Limited Special offer 6-months &thinsp; <b>30% OFF</b></Texting
          >
          <div class="d-flex align-center">
            <Texting
              type="p3 grey22"
              style="white-space: nowrap; margin-right: 7px !important"
              ><b>Uh oh!</b>&thinsp;this promo expires in</Texting
            >
            <div>
              <flip-countdown
                v-if="scheduleDate !== ''"
                :showSeconds="false"
                :deadline="scheduleDate"
                @timeElapsed="scheduleDate = ''"
              ></flip-countdown>
            </div>
            <div
              @click="upgradeButton($event)"
              class="d-flex justify-center upgrade-button align-center"
            >
              <Texting type="p2b mywhite" style="text-align: center"
                >Upgrade Now!</Texting
              >
              <Images
                width="18"
                height="18"
                style="margin-left: 16px"
                name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631734803/images/up-arrow_1_v6yrpp.svg"
              />
            </div>
          </div>
        </v-col>
        <v-col class="d-flex d-md-none px-6" cols="12">
          <v-row justify="center" align="center">
            <v-col cols="auto" class="d-block px-6 py-0">
              <Texting class="d-inline-block text-center" type="p4 mywhite"
                >Limited Special offer 6-months <b>30% OFF</b></Texting
              >
              <!--                      <span style="margin-left: 10px;font-weight: bold;font-size: 23px;line-height: 31px;color: #FFFFFF">30% OFF</span>-->
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto">
              <Texting
                type="p3 grey22"
                style="white-space: nowrap; margin-right: 7px !important"
                ><b>Uh oh!</b>&thinsp;this promo expires in</Texting
              >
            </v-col>
            <v-col cols="auto">
              <flip-countdown
                v-if="scheduleDate !== ''"
                :showSeconds="false"
                :deadline="scheduleDate"
                @timeElapsed="scheduleDate = ''"
              ></flip-countdown>
            </v-col>
            <v-col cols="auto">
              <div
                @click="upgradeButton"
                style="margin-top: -4px"
                class="d-flex justify-center upgrade-button align-center"
              >
                <Texting
                  type="p2b mywhite"
                  style="text-align: center; white-space: nowrap"
                  >Upgrade Now!</Texting
                >
                <Images
                  width="18"
                  height="18"
                  style="margin-left: 16px"
                  name="https://res.cloudinary.com/dyf7aejih/image/upload/v1631734803/images/up-arrow_1_v6yrpp.svg"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <UpsellModal
      :active="
        scheduleDate !== '' &&
        (getLocalData() !== 'true' || $route.query.six_month_promo === 'true')
      "
      @upgrade="upgradeButton"
      :deadline="scheduleDate"
      @closed="onUpsellClose"
    />
    <Confirmation
      ref="confirm"
      @done="handleDone"
      text1_params="p3 MainDark"
      header="Congratulations!"
      text1="Your subscription is upgraded to <b>6-month.<b>"
    />
  </div>
</template>

<script>
import myServices from "@/services/resource";
import { mapActions, mapState } from "vuex";
import Images from "./MyImages";
import Texting from "./Texting";
import AccountCredentials from "./AccountCredentials";
//import router from "@/router";
import axios from "axios";
import FlipCountdown from "vue2-flip-countdown";
import UpsellModal from "@/components/UpsellModal";
import Confirmation from "@/components/Confirmation";

export default {
  components: {
    Confirmation,
    UpsellModal,
    AccountCredentials,
    Texting,
    Images,
    FlipCountdown,
  },
  props: {
    menu: Boolean,
    projectDeleted: Boolean,
  },
  data: () => ({
    drawer: false,
    color: "primary",
    colors: ["primary", "blue", "success", "red", "teal"],
    right: true,
    miniVariant: false,
    expandOnHover: false,
    background: false,
    arrow_visible: true,
    scheduleDate: "",
    upsellId: "",
    upsellTitle: "",
  }),
  computed: {
    ...mapState({
      projects: "projects",
      invoices: "invoices",
      perks: "perks",
      user: "user",
    }),
    inArchive: function () {
      return this.projects.filter((project) => project.inArchive === true);
    },
  },
  watch: {
    scheduleDate(val) {
      if (val !== "") {
        let timerTexts = document.getElementsByClassName("flip-clock__slot");
        this.$nextTick(() => {
          for (let el of timerTexts) {
            //console.log('element ',el.innerHTML)
            if (el.innerHTML === "Days") el.innerHTML = "Day";
          }
        });
      }
    },
    projectDeleted(val) {
      if (val) {
        this.findUpsellProject();
      }
    },
    drawer(val) {
      if (val) {
        this.$emit("opened");
      } else {
        this.$emit("closed");
      }
    },
    menu(val) {
      if (val) {
        this.drawer = false;
      }
    },
  },
  async mounted() {
    new Promise(function (resolve, reject) {
      var s;
      s = document.createElement("script");
      s.src = "https://js.stripe.com/v3/";
      s.async = true;
      s.defer = true;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
    if (this.$route.query.six_month_confirmation === "true") {
      this.scheduleDate = "";
      this.$refs.confirm.show();
      this.$confetti.start({
        defaultType: "rect",
        defaultDropRate: 10,
      });
      setTimeout(() => {
        this.$confetti.stop();
      }, 3500);
    }
    this.getInvoices(this.user.userId);
    window.addEventListener(
      "storage",
      function (event) {
        if (event.key == "logout-event") {
          window.location.reload();
        }
      },
      {
        passive: true,
      },
    );
    //packageStartDate >2 && < 4
    // User -> usedFreeTrial
    await this.$store.dispatch("getProjects");
    await this.findUpsellProject();
  },
  methods: {
    findUpsellProject() {
      for (const pr of this.projects) {
        if (
          pr.isActiveProject &&
          pr.campaignStatus === "INDEMAND" &&
          (pr.packageType === "MONTHLY" || pr.packageType === "THREE_MONTH")
        ) {
          let startTime;
          startTime = 2 * 24 * 60 * 60;

          let startRange =
            (new Date().getTime() - new Date(pr.packageStartDate).getTime()) /
            1000;
          if (startRange >= startTime && startRange <= 7 * 24 * 60 * 60) {
            axios
              .get(`/api/schedule/checkOffer`)
              .then((res) => {
                const { scheduleDate } = res.data.data;
                this.upsellId = res.data.data.projectId;
                this.upsellTitle = res.data.data.projectTitle;
                this.scheduleDate =
                  new Date() <= new Date(scheduleDate)
                    ? new Date(scheduleDate).toString()
                    : "";
                if (new Date() > new Date(scheduleDate)) {
                  localStorage.setItem("upsell_promo_modal", "false");
                }
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                this.scheduleDate = "";
              });
          } else {
            this.scheduleDate = "";
          }
        } else {
          this.scheduleDate = "";
        }
      }
    },
    getLocalData() {
      return localStorage.getItem("upsell_promo_modal");
    },
    handleDone() {
      myServices.myAnalyticsEvents(
        this,
        "Upsell",
        "congrats done button click",
      );
    },
    onUpsellClose() {
      localStorage.setItem("upsell_promo_modal", "true");
      if (this.$route.query.six_month_promo) {
        this.$router.replace({
          query: null,
        });
      }
    },
    upgradeButton(e) {
      if (e) {
        myServices.myAnalyticsEvents(this, "Upsell", "sticky bar button click");
      }
      const projectId = this.upsellId;

      // const projectId = "777d5599-ddb9-4eab-8eba-f7b689e8d317";

      axios
        .get(`/api/package/upgrade/${projectId}`)
        .then((res) => {
          // this.$nextTick(() => (this.activateDisabled = false));
          this.sessionId = res.data.data.session;

          let stripe;
          if (process.env.NODE_ENV == "production") {
                        stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD); //eslint-disable-line
          } else if (process.env.NODE_ENV == "development") {
                        stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV); //eslint-disable-line
          }
          stripe
            .redirectToCheckout({ sessionId: this.sessionId.id })
            .then(() => {});
        })
        .catch((error) => {
          myServices.showErrorMessage(this, error);
          // this.activateDisabled = false;
        });
    },

    logoClick() {
      myServices.myAnalyticsEvents(
        this,
        "Homepage  Navigation bar",
        "Perkfection logo click",
      );
      setTimeout(() => {
        let elmnt = document.getElementById("home_user_container");
        elmnt.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
      /*this.$ga.event('Homepage  Navigation bar', 'Perkfection logo click');
            this.$mixpanel.track('Homepage  Navigation bar', {
              action: 'Perkfection logo click',
            });*/
    },
    arrowVisible: function () {
      if (
        localStorage.getItem("menu_arrow") === "false" ||
        (!localStorage.getItem("archive_added_once") &&
          !localStorage.getItem("invoice_added_once"))
      )
        return false;
      localStorage.setItem("menu_arrow", "true");
      if (
        (this.menuArchiveAdded() || this.menuInvoiceAdded()) &&
        localStorage.getItem("menu_arrow") === "true" &&
        this.arrow_visible
      ) {
        setTimeout(() => {
          localStorage.setItem("menu_arrow", "false");
          this.arrow_visible = false;
        }, 3000);
        return true;
      }
      return false;
    },
    menuArchiveAdded: function () {
      if (this.inArchive.length === 0)
        localStorage.setItem("archive_added_once", "");
      if (
        localStorage.getItem("archive_added_once") === "true" &&
        localStorage.getItem("menu_clicked") === "true"
      )
        return false;
      if (
        (this.inArchive.length === 1 ||
          (this.inArchive.length > 1 &&
            localStorage.getItem("menu_clicked") === "false")) &&
        this.$route.name !== "Archives"
      ) {
        localStorage.setItem("archive_added_once", "true");
        localStorage.setItem("menu_clicked", "false");
        return true;
      }
      return false;
    },
    menuInvoiceAdded: function () {
      if (
        localStorage.getItem("invoice_added_once") === "true" &&
        localStorage.getItem("menu_clicked_invoice") === "true"
      )
        return false;
      if (
        (this.invoices.length === 1 ||
          (this.invoices.length > 1 &&
            localStorage.getItem("menu_clicked") === "false")) &&
        this.$route.name !== "InvoicePage"
      ) {
        localStorage.setItem("invoice_added_once", "true");
        localStorage.setItem("menu_clicked_invoice", "false");
        return true;
      }
      return false;
    },
    menuClicked: function () {
      if (
        localStorage.getItem("archive_added_once") === "true" &&
        localStorage.getItem("menu_clicked") === "false"
      ) {
        localStorage.setItem("menu_clicked", "true");
        localStorage.setItem("menu_arrow", "true");
      }
      if (
        localStorage.getItem("invoice_added_once") === "true" &&
        localStorage.getItem("menu_clicked_invoice") === "false"
      ) {
        localStorage.setItem("menu_clicked_invoice", "true");
        localStorage.setItem("menu_arrow", "true");
      }
    },
    onClicked(value) {
      this.drawer = value;
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push({ name: "home" });
          localStorage.setItem("logout-event", "logout" + Math.random());
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    },
    ...mapActions(["getInvoices"]),
    ...mapActions(["getAllPerks"]),
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.account-menu-enter-active {
  transition: all 0.15s ease-in;
}

.account-menu-leave-active {
  transition: all 0.15s ease-in;
}

.account-menu-enter,
.account-menu-leave-to {
  transform: translateX(100px);
}

.invoice-div {
  border-top: 1px solid #f4f4f4;
  padding-top: 15px;
}

.archive-div-not-invoice {
  border-top: 1px solid #f4f4f4;
  padding-top: 15px;
}

.help-div {
  border-top: 1px solid #f4f4f4;
  padding-top: 15px;
  margin-top: 15px;
}

.red-circle {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fa5c7c;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  left: 25px;
  bottom: 31px;
}
.upgrade-button {
  max-width: 192px;
  background: linear-gradient(
    94.37deg,
    #ffc714 -18.36%,
    #ff9600 34.3%,
    #ff5008 108.67%
  );
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  padding: 5px 17px 3px 30px;
  margin-right: 20px;
  margin-left: 21px;
}
</style>
